/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

// Elements
import ParseContent from 'components/shared/ParseContent'
import ButtonSecondary from 'components/elements/Buttons/ButtonSecondary'
import AnchorPrimary from 'components/elements/Anchors/AnchorPrimary'

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-bottom: 2rem;
    & > strong {
      font-weight: ${({ theme }) => theme.font.weight.extraBold};
    }
  }
`

const Shutter = styled.div`
  background-color: ${({ theme }) => theme.color.light};

  & h2 {
    font-size: 20px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
`

const Animator = styled.span`
  font-size: 36px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.color.secondary};
`

interface AccordionProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Accordion
}

const Accordion: React.FC<AccordionProps> = ({ fields }) => {
  const [currentIndex, setCurrentIndex] = useState<number | null>(0)

  const descriptionVariants = {
    open: {
      opacity: 1,
      height: 'auto',
      overflow: 'hidden',
    },
    collapsed: {
      opacity: 0,
      height: 0,
      overflow: 'hidden',
    },
  }

  return (
    <section className="mb-5 pb-lg-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <div className="row">
              <Content content={fields.description} className="mb-5" />
              {fields.accordion?.map((a, index) => {
                const open: boolean = currentIndex === index

                return (
                  <motion.div
                    className="d-flex align-items-start justify-content-between"
                    onClick={() =>
                      open ? setCurrentIndex(null) : setCurrentIndex(index)
                    }
                    role="button"
                  >
                    <Shutter className="p-4 mb-4 w-100" key={index}>
                      <div className="d-flex justify-content-between align-items-center">
                        <h2>{a?.title}</h2>
                        <Animator>{open ? '-' : '+'}</Animator>
                      </div>
                      <AnimatePresence>
                        {open && (
                          <motion.div
                            initial="collapsed"
                            animate="open"
                            exit="collapsed"
                            variants={descriptionVariants}
                            transition={{
                              duration: 0.8,
                              ease: [0.04, 0.62, 0.23, 0.98],
                            }}
                          >
                            <ParseContent
                              content={a?.description}
                              className="py-4"
                            />
                            {a?.link && (
                              <div className="d-flex justify-content-end">
                                <AnchorPrimary href={a?.link?.url || '#'}>
                                  {a?.link?.title}
                                </AnchorPrimary>
                              </div>
                            )}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </Shutter>
                  </motion.div>
                )
              })}
            </div>
            <div className="d-flex mt-2">
              <div className="mx-auto">
                <ButtonSecondary to={fields.link?.url || '#'}>
                  {fields.link?.title}
                </ButtonSecondary>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Accordion
